<template>
  <div class="container-fluid">
    <form class="form px-0 pt-2">
      <div class="row">
        <div class=" font-weight-bold col-md-11">
          Name
        </div>
        <div class="col-md-11">
          <el-input class="w-100" placeholder="Enter name" v-model="newContribution.name" type="text" required />
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row mt-2" v-for="(item, index) in newContribution.payment" :key="index">
        <div class="
            col-md-11 font-weight-bold mt-3
          ">
          Contribution Item
        </div>

        <div class="col-11">
          <button class="default-btn w-100 text-left pr-1" type="button" style="
              border-radius: 4px;
              border: 1px solid #ced4da;
              color: #6c757d;
            " id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{
            item && item.financialContribution
              ? item.financialContribution.name
              : "Select"
          }}
            <i class="pi pi-chevron-down manual-dd-icon float-right pr-1"></i>
          </button>
          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
            <div class="row w-100 mx-auto">
              <div class="col-md-12">
                <input type="text" class="form-control" placeholder="Select contribution item" />
              </div>
            </div>

            <a class="dropdown-item font-weight-700 small-text py-2 c-pointer" v-for="(itm, indx) in contributionItems"
              :key="indx" @click="selectContribution(itm, index)">{{ itm.name }}</a>
            <a class="
                font-weight-bold
                small-text
                d-flex
                justify-content-center
                py-2
                text-decoration-none
                primary-text
              " style="border-top: 1px solid #002044; color: #136acd" href="#" type="button" data-toggle="modal"
              data-target="#exampleModalCenter">
              <i class="pi pi-plus-circle mr-2 d-flex align-items-center" style="color: #136acd"></i>
              Create new Contribution Item
            </a>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border: none">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Add Contribution
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <ContributionItems @item-name="newConItems" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-1 align-self-center">
          <i class="pi pi-trash" @click="showConfirmModal(item.financialContribution.id, index)"></i>
        </div>
      </div>
      <div class="col-md-5  mt-3">
        <el-button round style="background: #E0E9F4" v-on:click.prevent="addContribution" class="">
          <i class="pi pi-plus-circle icons" aria-hidden="true"></i>
          Add
        </el-button>
      </div>
      <div class="row mt-4">
        <!-- <div class="col-12">
          <hr class="mt-4" />
        </div> -->
        <div class="font-weight-bold mt-3 col-md-11">Choose Bank</div>
        <div class="col-md-11">
          <div class="dropdown w-100">
            <el-dropdown trigger="click" class="w-100">
              <span class="el-dropdown-link w-100">
                <el-input type="text" placeholder='Select Bank' v-model="bankSearchText" />
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in filteredBanks" :key="item.id" @click="setSelectedBank(item)">
                    {{ item ? item.name : "" }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>


        <div class="
            col-md-11 mt-3 font-weight-bold
          ">
          <div>Enter account number</div>
        </div>
        <div class="col-md-11 ">
          <el-input class="w-100" v-model="accountNumber" @blur="resolveCustomerDetail" />
        </div>
        <!-- <div class="col-2 d-none d-sm-block">
          <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <div class="col-sm-2 col-lg-3 align-self-center mt-4" v-if="loading">
          <div style="width: 3rem; height: 3rem" role="status">
            <el-icon class="is-loading">
              <Loading />
            </el-icon>
          </div>
        </div>

        <div class="
            mt-3
            col-md-11
            font-weight-bold
          ">
          <div>Account Name</div>
        </div>
        <div class="col-md-11 ">
          <el-input type="text" v-model="accountName" placeholder="Account name" class="w-100 " disabled />
          <div class="mt-1">
            <em class="mt-1">This will automatically come up, kindly confirm before clicking
              on save.</em>
          </div>
        </div>

        <!-- <div class="col-md-11 mt-5 d-flex align-items-center c-pointer" @click="showPaymentSection">
          <p class="mb-0">Select Payment Gateway</p>
          <hr class="mt-4" style="width: calc(100% - 80px)" />
          <el-icon>
            <ArrowDown />
          </el-icon>
        </div> -->

        <!-- <div class="mt-3 col-10 offset-sm-1 offset-md-0 col-md-3 col-lg-4 text-md-right align-self-center">
                    <div>Payment Gateway</div>
                </div> -->
        <!-- <div class="
            d-flex
            justify-content-center
            col-12 col-sm-10
            offset-sm-3
            col-md-6 col-lg-5
            pl-md-0
            mt-3
          " style="height: 43px" v-if="paymentGatewayNeeded.name" :class="{
            'payment-section': paymentSectionIsShown,
            'payment-section-hidden': !paymentSectionIsShown,
          }">
          <input type="checkbox" class="px-2" checked />
          <h6 class="px-2">{{ paymentGatewayNeeded.name }}</h6>
        </div> -->
        <div class="col-md-11">
          <div class="row justify-content-center mt-3">
            <hr class="w-100">
            <div class="text-font ">Select Payment Gateway</div>
            <div class="col-sm-4 d-flex" v-for="(item, index) in gateways" :key="item.id">
              <i class=" mr-2 check-box" @click="toggleCheckBox(item, index)">
                <img v-if="item.isChecked" src="../../assets/check.png" class="child w-100">
              </i>
              <h6>{{ item.name }}</h6>
            </div>
            <hr class="w-100">
          </div>
        </div>
        <div class="col-md-11 ml-2 text-center" v-if="gatewayNotification">
          <div class="text-danger font-weight-bold small">Please select one or two payment gateway</div>
        </div>
      </div>

      <div class="row" v-if="false">
        <div class="col-10 col-md-12 mt-4">
          <div class="d-flex">
            <h5 class="header-contri my-3">
              Choose the form template you desire
            </h5>
            <hr style="width: 60%" />
            <i class="pi pi-angle-up angle-icon mt-3" :class="{
            rollIcon: templateDisplay,
            closeIcon: !templateDisplay,
          }" @click="toggleTemplate"></i>
          </div>

          <div class="row img-row hide-tem mt-4" :class="{
            'show-tem': templateDisplay,
            'hide-tem': !templateDisplay,
          }">
            <div class="col-sm-6 col-lg-4 mt-3">
              <img src="../../assets/giving1.png" class="w-100" ref="myImg" id="myImg" @click="togglePopup" />
              <div class="col-sm-12 d-flex justify-content-between mt-4">
                <i class="check-it mr-2" @click="toggleFirstTemplate">
                  <span class="child" v-if="firstTemplate"></span>
                </i>
                <h6 class="preview" @click="togglePopup">Preview</h6>
              </div>
            </div>
            <ImageModal :src="sourceModal" :booleanModal="booleanModal" @close-modal="closeModal" />
            <!-- <div ref="modal" class="modal">
                            <span class="close">&times;</span>
                            <img class="modal-conent" ref="img01">
                            <div ref="caption"></div>
                        </div> -->
            <div class="col-sm-6 col-lg-4 mt-3">
              <img src="../../assets/giving2.png" class="w-100" style="height: 83%" ref="myImg" id="myImg"
                @click="togglePopup" />
              <div class="col-sm-12 d-flex justify-content-between mt-4">
                <i class="check-it mr-2" @click="toggleSecondTemplate">
                  <span class="child" v-if="secondTemplate"></span>
                </i>
                <h6 class="preview" @click="togglePopup">Preview</h6>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 mt-3">
              <img src="../../assets/giving3.png" class="w-100" ref="myImg" id="myImg" @click="togglePopup" />
              <div class="col-sm-12 d-flex justify-content-between mt-4">
                <i class="check-it mr-2" @click="toggleThirdTemplate">
                  <span class="child" v-if="thirdTemplate"></span>
                </i>
                <h6 class="preview" @click="togglePopup">Preview</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="
            col-12 col-sm-10 col-md-6 col-lg-5
            offset-sm-1 offset-md-3 offset-lg-4
            pl-0
            mt-3
          ">
          <el-button size="large" round :loading="loadingSave" class="w-100 "
            :class="{ 'disabled-bg': disabled, 'primary-bg': !disabled }" @click.prevent="saveAndContinue">
            <span class="text-white">Save and Continue</span>
          </el-button>
        </div>
      </div>
    </form>
    <ConfirmDialog />
    <Toast />
  </div>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import axios from "@/gateway/backendapi";
import finish from "../../services/progressbar/progress";
import axio from "axios";
import { useStore } from "vuex";
import router from "@/router/index";
import { useRoute } from "vue-router";
// import Store from "../../store/store"
import store from "../../store/store";
import ContributionItems from "@/components/firsttimer/contributionItemModal";
import ImageModal from "../../views/donation/ImageModal";
// import ToggleButton from  '../../views/donation/toggleButton'
import { ElMessage, ElMessageBox } from "element-plus";
import description from '../../views/workflow/helper/description';

export default {
  components: {
    Dropdown,
    ContributionItems,
    ImageModal,
  },
  props: ["header", "formID" ],
  emits: ["formcreated"],
  directives: {
    tooltip: Tooltip,
  },
  setup(props, { emit }) {
    const contributionItems = ref([]);
    const newContribution = ref({ payment: [{}] });
    const nigerianBanks = ref([]);
    const selectedBank = ref("");
    const accountNumber = ref("");
    const selectedContribution = ref("");
    const accountName = ref("");
    const bankSearchText = ref("");
    const loading = ref(false);
    const gatewayNotification = ref(false);
    const disabled = ref(true);
    const route = useRoute();
    const firstTemplate = ref(true);
    const secondTemplate = ref(false);
    const thirdTemplate = ref(false);
    const sourceModal = ref("");
    const booleanModal = ref(false);
    const paymentGateWaysDb = ref([]);
    const paymentGateWays = ref([]);
    const loadingSave = ref(false);
    const loadingEdit = ref(false);
    const removeContributionIDs = ref([]);
    const removePaymentGatewayIDs = ref([]);
    const isActive = ref(null);
    const routeParams = ref(route.params.editPayment);
    const theContributionItems = ref([]);
    const templateDisplay = ref(false);
    const paymentSectionIsShown = ref(false);

    console.log(props.formID, 'hhhhh');

    const showPaymentSection = () => {
      paymentSectionIsShown.value = !paymentSectionIsShown.value;
    };

    const addContribution = () => {
      newContribution.value.payment.push({
        financialContribution: {},
      });
      console.log(newContribution.value);
    };

    const getContributionItems = async () => {
      // To get from store use useStore, to save to store use the path that leads to store
      const store = useStore();
      let storedItems = store.getters["contributions/contributionItems"];
      if (storedItems.length > 0) {
        contributionItems.value =
          store.getters["contributions/contributionItems"];
      } else {
        try {
          const { data } = await axios.get(
            "/api/financials/contributions/items"
          );
          console.log(data);
          contributionItems.value = data;
        } catch (error) {
          finish();
          console.log(error);
        }
      }
    };
    getContributionItems()

    const getBanks = () => {
      axios
        .get("/api/Financials/GetBanks")
        .then((res) => {
          console.log(res);
          nigerianBanks.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getBanks();

    const filteredBanks = computed(() => {
      if (!bankSearchText.value) return nigerianBanks.value;
      return nigerianBanks.value.filter((i) =>
        i.name.toLowerCase().includes(bankSearchText.value.toLowerCase())
      );
    });

    const paymentGatewayNeeded = ref({});

    const getGateWays = () => {
      // if (!route.params.editPayment) {
      axios
        .get("/api/Financials/GetPaymentGateways")
        .then((res) => {
          console.log(res);
          paymentGateWaysDb.value = res.data.map((i) => {
            return {
              countryCoverageArea: i.countryCoverageArea,
              id: i.id,
              isActive: i.isActive,
              isSubAccountSupported: i.isSubAccountSupported,
              name: i.name,
              isChecked: false,
            };
          });
          // paymentGatewayNeeded.value = paymentGateWaysDb.value.find(
          //   (i) => i.name === "Paystack"
          // );
          console.log(paymentGatewayNeeded.value, "God is Good");
          console.log(paymentGateWaysDb.value);
          // nigerianBanks.value = res.data.data
          finish();
        })
        .catch((err) => {
          finish();
          console.log(err);
          ElMessage({
            type: "info",
            message: "Unable to get banks, please try again",
            duration: 5000,
          });
        });
    };
    //   }
    getGateWays();

    const gateways = computed(() => {
      // if (!route.params.editPayment) return paymentGateWaysDb.value;
      const x = paymentGateWaysDb.value.filter(i => {
        const index = paymentGateWays.value.findIndex(j => j.id === i.id);
        if (index >= 0) {
          // alert(j)
          // console.log(i)
          i.isChecked = true;
        }

        // alert(i)
        return i.name.toLowerCase().includes("paystack") || i.name.toLowerCase().includes("flutterwave")
      })

      return x;
    })



    // const deleteContribution = (item, index) => {
    //
    // if (route.params.editPayment) {
    //     console.log(item)
    //     removeContributionIDs.value.push(item.id)

    //     console.log(removeContributionIDs.value)
    // }

    // }

    const setSelectedBank = (item) => {
      bankSearchText.value = item.name;
      selectedBank.value = item;
    };
    const showConfirmModal = (id, index) => {
      ElMessageBox.confirm(
        "Are you sure you want to proceed?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          deleteContribution(id, index);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    };

    const deleteContribution = (id, index) => {
      newContribution.value.payment.splice(index, 1);
      //   axios
      //     .delete(
      //       `/mobile/v1/PaymentForm/contributionItem?contributionItemID=${id}`
      //     )
      //     .then((res) => {
      //       console.log(res);
      //       if (res.data) {
      //         toast.add({
      //           severity: "success",
      //           summary: "Delete Successful",
      //           detail: `Contribution Transaction Deleted`,
      //           life: 3000,
      //         });
      //       } else {
      //         toast.add({
      //           severity: "warn",
      //           summary: "Delete Failed",
      //           detail: `Please Try Again`,
      //           life: 3000,
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       finish();
      //       if (err.response) {
      //         console.log(err.response);
      //         toast.add({
      //           severity: "warn",
      //           summary: "Unable to delete",
      //           detail: `${err.response}`,
      //           life: 3000,
      //         });
      //       } else if (err.toString().toLowerCase().includes("network error")) {
      //         toast.add({
      //           severity: "warn",
      //           summary: "Network Error",
      //           detail: `Please Ensure you have a strong internet connection`,
      //           life: 3000,
      //         });
      //       } else if (err.toString().toLowerCase().includes("timeout")) {
      //         toast.add({
      //           severity: "warn",
      //           summary: "Request Delayed",
      //           detail: "Make sure you have a strong internet connection",
      //           life: 3000,
      //         });
      //       } else {
      //         toast.add({
      //           severity: "warn",
      //           summary: "Delete Failed",
      //           detail: `Please Try Again`,
      //           life: 3000,
      //         });
      //       }
      //       console.log(err);
      //     });
    };

    const resolveCustomerDetail = async () => {
      loading.value = true;

      try {
        let { data } = await axio.post(
          `https://api.ravepay.co/flwv3-pug/getpaidx/api/resolve_account`,
          {
            recipientaccount: accountNumber.value,
            destbankcode: selectedBank.value.code,
            PBFPubKey: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE,
          }
        );
        console.log(data);
        accountName.value = data.data.data.accountname;
        disabled.value = false;

        loading.value = false;

        if (data.data.data.responsemessage.toLowerCase().includes("sorry")) {
          ElMessage({
            type: "warning",
            message: data.data.data.responsemessage,
            duration: 5000,
          });
        } else {
          ElMessage({
            type: "success",
            message: "Account Check Successful",
            duration: 5000,
          });
        }
      } catch (error) {
        finish();
        console.log(error);

        loading.value = false;

        if (!accountNumber.value || accountNumber.value === "") {
          ElMessage({
            type: "warning",
            message: "No account number found",
            duration: 5000,
          });
        } else {
          ElMessage({
            type: "error",
            message: "Account Check Error, Please check your banks details again",
            duration: 5000,
          });
        }
      }
    };

    const toggleCheckBox = (item) => {
      if (item.isChecked) {
        gatewayNotification.value = true
      } else {
        gatewayNotification.value = false
      }

      item.isChecked = !item.isChecked

      if (item.isChecked && paymentGateWays.value.findIndex(i => i.id === item.id) < 0) {

        if (item.name.toLowerCase().includes('flutterwave')) {
          try {
            axios.get(`/api/PaymentForm/subaccounts?accountNumber=${accountNumber.value}`).then(res => {
              if (res.data.length > 0) {
                ElMessageBox.confirm(
                  `This account details has been recorded with Flutterwave as ${res.data[0].meta[0].meta_name}, Do you want to use it?`,
                  // "Confirm delete",
                  {
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    type: "info",
                  }
                )
                  .then(() => {
                    ElMessage({
                      type: "success",
                      message: "Confirmed, The selected acount is now in use",
                      duration: 3000,
                    });
                    item.subAccountID = res.data[0].subaccount_id
                    paymentGateWays.value.push(item)
                  })
                  .catch(() => {
                    ElMessage({
                      type: "info",
                      message: "Please enter another bank account details to enable Flutterwave subaccount integration",
                      duration: 3000,
                    });
                    accountNumber.value = ""
                    accountName.value = ""
                    selectedBank.value = new Object()
                  });
              } else {
                paymentGateWays.value.push(item)
              }
            })
          }
          catch (err) {
            console.log(err)
          }
        } else {
          paymentGateWays.value.push(item)
        }
      } else {
        paymentGateWays.value = paymentGateWays.value.filter(i => {
          return i.id !== item.id
        })
        removePaymentGatewayIDs.value.push(item.id)
      }
    }

    const saveAndContinue = async () => {
      if (gateways.value && gateways.value[0].isChecked === false && gateways.value && gateways.value[1].isChecked === false) {
        gatewayNotification.value = true
      } else {
        gatewayNotification.value = false
        loadingSave.value = true;

        let removeEmptyObj = newContribution.value.payment.filter((i) => {
          return Object.keys(i.financialContribution).length > 0;
        });

        let paymentForm = {
          name: newContribution.value.name,
          bankCode: selectedBank.value.code,
          accountName: accountName.value,
          accountNumber: accountNumber.value,
          // description: description.value,
          isActive: isActive.value,
          contributionItems: removeEmptyObj.map((i) => {
            let id = i.financialContribution.id;
            return { financialContributionID: id };
          }),
          paymentGateWays: [{ paymentGateWayID: paymentGatewayNeeded.value.id }],
        };

        if (!props.formID) {
          try {
            const res = await axios.post(
              "/api/PaymentForm/newpaymentform",
              paymentForm
            );
            console.log(res);
            loadingSave.value = false;
            // toast.add({severity:'success', summary: 'Account Check Error', detail:'Please check your banks details again', life: 3000});
            // store.dispatch('contributions/paymentData', res.data)

            finish();
            paymentForm.bank = selectedBank.value.name;
            emit("formcreated", paymentForm);
          } catch (err) {
            finish();
            console.log(err);
            loadingSave.value = false;

            // toast.add({severity:'error', summary: '', detail:'Please check your banks details again', life: 3000});
          }
        } else {
          (paymentForm.contributionItems = newContribution.value.payment.map(
            (i) => {
              return { financialContributionID: i.financialContribution.id };
            }
          )),
            // paymentForm.contributionItems = theContributionItems.value.map(i => {
            //     return {
            //         financialContributionID: i.financialContributionID,
            //         id: i.id,
            //         paymentFormID: i.paymentFormID
            //     }
            // })
            (paymentForm.id = props.formID);
          paymentForm.removeContributionIDs = removeContributionIDs.value;
          paymentForm.removePaymentGatewayIDs = removePaymentGatewayIDs.value;
          try {
            const res = await axios.put(`/api/PaymentForm/update`, paymentForm);
            console.log(res);
            loadingSave.value = false;
            store.dispatch("contributions/paymentData", res.data);
            // router.push({
            //   name: "PaymentOption",
            //   params: { paymentId: res.data.id },
            // });
            emit("formcreated", paymentForm);

            finish();
          } catch (err) {
            console.log(err);
            loadingSave.value = false;
            finish();
          }
        }
      }

    };
    const selectContribution = (item, index) => {
      // if (newContribution.value.payment.findIndex(i => i.id === item.id) < 0) {
      // newContribution.value.payment[newContribution.value.payment.length - 1] = item
      newContribution.value.payment[index].financialContribution = item;
      // }   else {
      console.log("Youve selected this, please select another");
      // }
      // newContribution.value.offType = e.target.innerText
      // newContribution.value.payment.push(item)
      console.log(item, index, newContribution.value.payment);
    };

    // const getSavedPayments = async () => {
    //     try {
    //         const res = await axios.get("/api/PaymentForm/GetAll");
    //         console.log(res)
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }
    // getSavedPayments()

    const active = (payload) => {
      isActive.value = payload;
    };

    const getEditPayment = async () => {
      loadingEdit.value = true;
      if (props.formID) {
        try {
          const res = await axios.get(
            `/api/PaymentForm/GetOne?paymentFormID=${props.formID}`
          );
          console.log(res);
          loadingEdit.value = false;
          newContribution.value.name = res.data.name;
          theContributionItems.value = res.data.contributionItems;
          newContribution.value.payment = res.data.contributionItems.map(
            (i) => i
          );
          accountNumber.value = res.data.accountNumber;
          accountName.value = res.data.accountName;
          // bankSearchText.value = nigerianBanks.value && nigerianBanks.value.find(i => i.code == res.data.bankCode).name  ? nigerianBanks.value.find(i => i.code == res.data.bankCode).name : "",
          
            (isActive.value = res.data.isActive);
          paymentGateWays.value = res.data.paymentGateWays.map((i) => {
            return {
              name: i.paymentGateway.name,
              countryCoverageArea: i.paymentGateway.countryCoverageArea,
              id: i.paymentGateway.id,
              isActive: i.paymentGateway.isActive,
              isSubAccountSupported: i.paymentGateway.isSubAccountSupported,
            };
          });
          console.log(newContribution.value.payment);

          disabled.value = false;
        } catch (err) {
          console.log(err);
          finish();
          ElMessage({
            type: "error",
            message: "Network Error",
            duration: 5000,
          });
        }
      } else {
        isActive.value = true;
      }
    };
    getEditPayment();

    watchEffect(() => {
      if(props.formID){
        getEditPayment()
      }
    })



    const newConItems = (payload) => {
      console.log(payload);
      contributionItems.value.push(payload);
      newContribution.value.payment[newContribution.value.payment.length - 1] =
        payload;
    };

    const toggleFirstTemplate = () => {
      firstTemplate.value = !firstTemplate.value;
      secondTemplate.value = false;
      thirdTemplate.value = false;
    };

    const toggleSecondTemplate = () => {
      secondTemplate.value = !secondTemplate.value;
      firstTemplate.value = false;
      thirdTemplate.value = false;
    };

    const toggleThirdTemplate = () => {
      thirdTemplate.value = !thirdTemplate.value;
      firstTemplate.value = false;
      secondTemplate.value = false;
    };

    const togglePopup = (e) => {
      if (e.target.localName == "img") {
        booleanModal.value = true;
        sourceModal.value = e.target.src;
      } else {
        booleanModal.value = true;
        sourceModal.value =
          e.target.parentElement.previousElementSibling.currentSrc;
      }
      // console.log(e)
    };

    const closeModal = (payload) => {
      booleanModal.value = payload;
    };

    const toggleTemplate = () => {
      templateDisplay.value = !templateDisplay.value;
    }

    return {
      contributionItems,
      gatewayNotification,
      newContribution,
      addContribution,
      deleteContribution,
      nigerianBanks,
      selectedBank,
      bankSearchText,
      filteredBanks,
      resolveCustomerDetail,
      accountNumber,
      saveAndContinue,
      selectContribution,
      selectedContribution,
      accountName,
      loading,
      loadingSave,
      loadingEdit,
      disabled,
      newConItems,
      firstTemplate,
      secondTemplate,
      thirdTemplate,
      toggleFirstTemplate,
      toggleSecondTemplate,
      toggleThirdTemplate,
      sourceModal,
      togglePopup,
      booleanModal,
      closeModal,
      paymentGateWaysDb,
      paymentGateWays,
      toggleCheckBox,
      gateways,
      removeContributionIDs,
      removePaymentGatewayIDs,
      isActive,
      routeParams,
      theContributionItems,
      templateDisplay,
      toggleTemplate,
      active,
      showConfirmModal,
      paymentGatewayNeeded,
      showPaymentSection,
      paymentSectionIsShown,
      setSelectedBank,
    };
  },
};
</script>

<style scoped>
.payment-section-hidden {
  transition: all 0.8s ease-in-out;
  height: 0 !important;
  overflow: hidden !important;
}

.payment-section {
  transition: all 0.8s ease-in-out;
  height: 25px !important;
  overflow: hidden !important;
}

.form {
  padding: 25px;
}

.btnIcons {
  width: 140px;
  height: 35px;
  color: #136acd;
  background-color: #dde2e6;
  border-radius: 40px;
  border: none;
}

.page-header {
  font: normal normal 800 29px Nunito sans;
}

.contri-item {
  padding: 20px 0 0 0;
  font: normal normal 700 20px Nunito Sans;
}

.disabled-bg {
  background: #136acda8;
  cursor: not-allowed;
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.check-box {
  border: 2px solid #136acdcc;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  margin-top: -1px;
}

.check-box .child {
  /* border: 2px solid red; */
  /* display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #136acd;
  position: relative;
  left: 3px;
  top: -3px; */
  margin-top: -8px;
}

.img-row img {
  box-shadow: 0px 3px 6px #2c28281c;
  /* border: 2px solid red; */
  border-radius: 15px;
}

.check-it {
  border: 2px solid #136acd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -1px;
}

.check-it .child {
  /* border: 2px solid red; */
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #136acd;
  position: relative;
  left: 3px;
  top: -4px;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

.preview {
  font-size: 0.8em;
  color: #136acd;
  font-style: italic;
  cursor: pointer;
}

.header-contri {
  font-size: 20px;
  font-weight: 700;
}

.angle-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid rgb(73, 73, 73);
  padding: 3px;
}

.hide-tem {
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.show-tem {
  height: 335px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.rollIcon {
  transform: rotateZ(180deg);
  transition: all 0.5s ease-in-out;
}

.closeIcon {
  transform: rotateZ(0deg);
  transition: all 0.5s ease-in-out;
}

.dropdown-menu {
  max-height: 300px;
  overflow: scroll;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}
</style>