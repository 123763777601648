<template>
  <div class="row justify-content-center">
    <div class="col-md-11">
      <div class="row justify-content-between">
        <div class="col-md-7 mt-5">
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-head font-weight-bold h2">Customize your App</div>

                  <div class="mt-3"><span class="text-font ">NB:</span> <span class=" text-font ">Please choose a
                      dark shade color, click out of the color box, the system will verify your color, then click save
                      to
                      continue.</span></div>
                </div>

                <!-- MOBILE AREA -->
                <!-- <div class="my-2">
                  <div class="container"></div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-11 bg-white pb-5 px-3 rounded-lg">
              <div class="row mt-1">
                <div class="col-md-12">
                  <div class="row  align-items-center">
                    <div class="d-flex col-12 align-items-center col-md-6">
                      <div class="text-font font-weight-600 mb-0">Choose Primary Colour</div>
                      <!-- <i class="mt-1 pl-2 pi pi-question-circle c-pointer" v-tooltip.top="'Choose brand color'"></i> -->
                    </div>

                    <div class="col-md-6  col-12  color-picker">
                      <img class="c-pointer w-50" src="../../../assets/mobileonboarding/colorpickericon.png"
                        alt="color-picker" @click="showColorPallet" />

                      <div class="col-4 col-sm-4 text-right">
                        <!-- <label for="colorpicker"></label> -->
                        <input type="color" id="colorpicker" class="no-border form-control c-pointer"
                          v-model="colorPicked" @change="saveAppBranding" ref="colorBox" style="width: 0; height: 0" />
                      </div>
                    </div>
                    <!-- <div class="col-md-3 col-12 col-sm-0"></div> -->
                    <!-- <hr class="mt-2 d-none d-sm-none d-md-block" /> -->
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-12">
                  <div class="row mt-2">
                    <div class="col-12 col-sm-6">
                      <div class="text-font font-weight-600 mt-2  ">Upload Ministry Logo</div>
                    </div>
                    <Toast />
                    <!-- testrun -->
                    <!-- <div class="col-md-5 col-12">
                  <div class="container mx-auto mx-md-0 mx-lg-0 pt-2">
                    <div class="photo-box border ml-1">
                      <img src="" class="w-100 h-100 img-responsive">
                    </div>
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        @change="uploadFile"
                      />
                      <label
                        class="custom-file-label px-4"
                        for="customFile"
                      ></label>
                    </div>
                  </div>
                </div> -->
                    <!-- testrun -->
                    <div class="col-12 col-sm-6">
                      <!-- <div class="cs-input border-0 mt-2 ml-4">
                  Upload logo
                  <label
                    for="imgUpload"
                    class="choose-file mr-sm-4"
                    :class="{ 'hide-input': imageURL }"
                  >
                  </label> -->
                      <input type="file" class=" form-control" id="imgUpload" @change="imageSelected" />
                      <!-- </div> -->
                    </div>
                    <div class="row">
                      <div class="col-12 mt-3 d-flex justify-content-center">
                        <img :src="imageURL" style="width: 50%" />
                      </div>
                    </div>
                    <LoadingComponent :loading="loading" style="font-size: 1rem" />

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row justify-content-center  ">
            <div class="col-md-6 mt-5 cursor-pointer  " @click="saveAppDetails">
              <el-button size="large" round class="text-white w-100 primary-bg">Save and continue</el-button>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div @click="skip" class=" cursor-pointer text-center col-12 col-sm-6 ">
              Skip for now
            </div>
          </div>
        </div>

        <div class="col-md-5 b mb-4  d-none d-md-block">
          <!-- <img src="../../../assets/mobileonboarding/Iphone16.png" alt=""> -->

          <div class="smartphone">
            <div class="content">
              <iframe style="width: 100%; border: none; height: 100%" />
              <div class="mobile-container-holder ">
                <div class="container py-2 text-white small-text mt-3 " :style="{ backgroundColor: colorPicked }">
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-between">
                      <div class="small col-md-6 px-0">Faith Connect</div>
                      <div class="col-md-6 px-0 d-flex justify-content-end">
                        <div class="">
                          <img class="w-100" style="height: 1rem;"
                            src="../../../assets/mobileonboarding/MagnifyingGlass.png" alt="">
                        </div>
                        <div class="mx-1">
                          <img class="w-100" style="height: 1rem;" src="../../../assets/mobileonboarding/Gift.png"
                            alt="">
                        </div>
                        <div class="">
                          <img class="w-100" style="height: 1rem;" src="../../../assets/mobileonboarding/DotsThree.png"
                            alt="">
                        </div>


                      </div>
                      <!-- <h4 class="Header-text-small text-left">My Profile</h4> -->
                    </div>
                    <div class="col-md-12 mt-2 small d-flex justify-content-between">
                      <div>Faith</div>
                      <div>Social</div>
                      <div>Finance</div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid mb-2">
                  <div class="row  justify-content-cent">
                    <div class="col-md-12 d-flex px-0 justify-content-between mx-1 mt-2  small font-weight-700">
                      <div class="text-small">Invite</div>
                      <div class="text-small">Next Steps</div>
                      <div class="text-small">Ministry info</div>
                    </div>
                    <div class="col-md-12 px-0" >
                      <img class="w-100" style="height: 6rem;" src="../../../assets/mobileonboarding/familypic.png">
                    </div>
                    <div class="small col-md-12 px-0 mt-1">
                      <span class="text-small font-weight-bold">Trending Messages</span>
                    </div>
                    <div class="col-md-12 d-flex px-1">
                      <div class="">
                        <img class="w-100" src="../../../assets/mobileonboarding/thanksgiving.png" alt="">
                      </div>
                      <div class=" mx-2">
                        <img class="w-100" src="../../../assets/mobileonboarding/festival.png" alt="">
                      </div>
                      <div class="">
                        <img class="w-100" src="../../../assets/mobileonboarding/prayer.png" alt="">
                      </div>
                    </div>
                    <div class="col-md-12 px-0">
                      <div>
                        <img class="w-100" style="height: 4.5rem;" src="../../../assets/mobileonboarding/footericon.png" alt="">
                      </div>
                    </div>
                    <div class="col-md-12 mt-1 d-flex justify-content-between" >
                      <div class="" >
                        <homeicon :colorCode="colorPicked" />
                      </div>
                      <div class="">
                        <img class="w-100" style="height: 2.3rem;" src="../../../assets/mobileonboarding/media.png" alt="">
                      </div>
                      <div class="">
                        <img class="w-100" style="height: 2.3rem;" src="../../../assets/mobileonboarding/give.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import router from "../../../router";
import { ref, computed } from "vue";
import homeicon from "../../../components/svg/homeicon"
// import store from "../../../store/store";
import axios from "@/gateway/backendapi";
import Tooltip from "primevue/tooltip";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { useToast } from "primevue/usetoast";
import mobile_service from "../../../services/mobile/mobile-service"
import { color } from "highcharts";

// import {ref} from 'vue'
export default {
  directives: {
    tooltip: Tooltip,
  },
  components: {
    LoadingComponent,
    homeicon
  },
  setup(props, context) {
    const image = ref("");
    const colorBox = ref("");
    const colorPicked = ref("");
    const colorValid = ref("");
    const toast = useToast();

    const changeColors = computed(() => {
      console.log(colorPicked);
      return { colorPicked: colorPicked.value };
    });

    const showColorPallet = () => {
      colorBox.value.click();
      console.log(colorBox);
    };

    const loading = ref(false);
    const saveAppBranding = () => {
      loading.value = true;
      let stringedColor = `${colorPicked.value}`
      mobile_service.validateColor(stringedColor)
        .then((res) => {
          console.log(res);
          colorValid.value = res.data;
          loading.value = false;

          toast.add({
            severity: "success",
            summary: "Color Matched",
            detail: "Choose logo or click save button to continue",
            life: 6000,
          });



        })
        .catch((err) => {
          toast.add({
            severity: "info",
            summary: "",
            detail: "Choose darker shade of the color",
            life: 4000,
          });
          console.log(err);
          loading.value = false;
        });
    };

    const getSocialMediaDetails = () => {
      axios
        .get(`/mobile/v1/Profile/GetChurchProfile`)
        .then((res) => {
          console.log(res);
          imageURL.value = res.data.returnObject.logoUrl
          colorPicked.value = res.data.returnObject.churchAppBackgroundColor


        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSocialMediaDetails()

    const saveAppDetails = () => {
      const formData = new FormData();
      formData.append("Logo", image.value);
      formData.append("BackgroundColour", colorPicked.value);

      axios
        .put(`/mobile/v1/Profile/CustomizeApp`, formData)
        .then((res) => {
          console.log(res);
          let changeState = {
            tab: true,
            churchSetup: false,
            socialMedia: false,
            appBranding: false,
            donationForm: true,
            subscription: false
          }
          context.emit('saved-appbranding', changeState)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //saveAppDetails();
    const imageURL = ref("");
    const imageSelected = (e) => {
      image.value = e.target.files[0];
      imageURL.value = URL.createObjectURL(image.value);
      console.log(image.value);
      console.log(e)
    };

    const skip = () => {
      if (imageURL.value && colorPicked.value) {
        let changeState = {
          tab: true,
          churchSetup: false,
          socialMedia: false,
          appBranding: false,
          donationForm: true,
          subscription: false
        }
        context.emit('saved-appbranding', changeState)
        console.log('all filllted');
      } else {
        let changeState = {
          // tab: true,
          churchSetup: false,
          socialMedia: false,
          appBranding: false,
          donationForm: true,
          subscription: false
        }
        context.emit('saved-appbranding', changeState)
        console.log('not all')
      }

      //   let changeState = {
      //       // tab: true,
      //       churchSetup: false,
      //       socialMedia: false,
      //       appBranding: false,
      //       donationForm: true
      //     }
      //     context.emit('saved-appbranding', changeState)
    }

    return {
      saveAppDetails,
      saveAppBranding,
      colorPicked,
      changeColors,
      showColorPallet,
      colorBox,
      imageSelected,
      imageURL,
      colorValid,
      loading,
      skip
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Raleway:wght@100..900&family=Roboto:wght@100&display=swap');

/* * {
    font-family: Poppins;
} */

.text-head {
  font-family: Raleway !important;
}

.text-font {
  font-family: Poppins !important;
  ;
}

.hide-input {
  height: 0;
  width: 0;
}

/* profile image area */
.image-container {
  border: 1px solid #ebeff4;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.profile-text {
  color: #031c39;
  font: normal normal 300 15px/20px Nunito Sans;
}

.profile-heading-text {
  color: #031c39;
  font: normal normal 700 20px/30px Nunito Sans;
}

.icon-size {
  font-size: 2rem;
}

.footer-text {
  color: #031c39;
  font: normal normal 200 20px Nunito Sans;
}

/*end of  profile image area */

/*css for mobile frame */
/* style in iframe */
.mobile-container-holder {
  position: absolute;
  top: -16px;
  width: 194px
}

/* .image-container-small {
  border: 1px solid#ebeff4;
  height: 50px;
  width: 50px;
  border-radius: 50%;
} */

.Header-text-small {
  color: #031c39;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: bold;
}

.profile-text-small {
  color: #031c39;
  font-family: Nunito Sans;
  font-size: 9px;
  font-weight: 500;
}

.font-size-small {
  font-size: 8px;
}

.pi-font-small {
  font-size: 12px;
}

.background-color-small {
  background-color: #ebeff4;
}

.reduced-icon-small {
  font-size: 12px;
}

/*end style in iframe */

/* The device with borders */
.smartphone {
  position: relative;
  width: 226px;
  height: 439px;
  margin: auto;
  top: 17.2rem;
  left: 13%;
  transform: translate(-50%, -50%);
  border: 16px black solid;
  border-top-width: 30px;
  border-bottom-width: 30px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  position: relative;
  width: 100%;
  height: 100%;
  /* height: 290px; */
  background: white;
}

/*end of css for mobile frame */

.backgroundImage {
  background-image: url("../../../assets/mobileonboarding/app1.svg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-color: #cccccc;
  /* max-height: 100%;*/
  height: 100vh;
  width: 100%;
  position: relative;
}

.appHeading1 {
  font: normal normal 800 20px/60px Nunito Sans;
  letter-spacing: 0px;
  color: #031c39;
  opacity: 1;
}

.no-border {
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline-style: 0;
}

hr {
  width: 558px;
  height: 0px;
  margin: auto;
}

#logoBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dde2e6;
  border-radius: 5px;
  opacity: 1;
  width: 437px;
  height: 45px;
}

.colourText {
  text-align: left;
  font: normal normal bold 18px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #020e1c;
  opacity: 1;
}

.appBranding {
  color: #031c39;
  font: normal normal 800 30px/40px Nunito Sans;
}

/* #colorpicker{
text-align: left;
font: normal normal bold 18px/24px Nunito Sans;
letter-spacing: 0px;
color: #020E1C;
opacity: 1;
} */
#colorpicker {
  /* -webkit-appearance: none; */
  padding: 0;
  border: none;
  border-radius: 10px;
  /* width: 20px;
  height: 20px; */
}

#colorpicker::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}

#colorpicker::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}


.button1 {
  background: #f1faff 0% 0% no-repeat padding-box;
  border: 0.20000000298023224px solid #7894a6;
  border-radius: 23px;
  opacity: 1;
  width: 180px;
  height: 46px;
  color: #136acd;
}

.button2 {
  text-align: center;
  height: 0;
  position: absolute;
  top: 80%;
  right: 40%;
}

.dash {
  width: 41px;
  height: 4px;
  background: #136acd 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
}

.dash1 {
  width: 41px;
  height: 4px;
  background: #f7f8fa 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
}

.smallButton {
  background: #f1faff 0% 0% no-repeat padding-box;
  border: 0.20000000298023224px solid #7894a6;
  border-radius: 23px;
  opacity: 1;
  width: 180px;
  height: 46px;
}


.step {
  font: normal normal bold 18px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
</style>
